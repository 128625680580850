<template>
    <div class="page_container" :class="{'straight_length': borderPosition}">
        <div class="page_head">
            <div class="page_head-item">
                <div class="page_head-title">
                    LEADS
                </div>
                <div class="page_head-total-btn">
                    {{all_leads.length || 0}} Total
                </div>
            </div>
            <div class="page_head-item">
                <div class="search_loop">
                    <b-form-input  class="mr-sm-2" placeholder="Search Leads" v-model="keyword"></b-form-input>
                    <div class="s_loop"></div>
                </div>
                <div class="mx-3">
                    <b-form-select v-model="selectedLeadType">
                        <template #first>
                            <b-form-select-option value="">All Leads</b-form-select-option>
                        </template>
                        <option
                                v-for="(option, id) in leadType"
                                :key="id"
                                :value="option.id"
                                :title="option.type || null"
                        >
                            {{option.type}}
                        </option>
                    </b-form-select>
                </div>
                <div>
                    <b-button class="page_head-new-btn" @click="newLead()" id="lead-btn">+New</b-button>
                </div>
            </div>
        </div>
        <div class="page_content">
            <b-table hover :items="filteredLeads" :fields="fields"  responsive="sm">

                <template v-slot:cell(status)="data">

                    <div class="item_status"
                         v-if="data.item.status"
                         :style="{border: `1px solid ${data.item.status.color}`, color: `${data.item.status.color}`}"
                    >
                        <b-form-select v-model="data.item.status_id" @change="changeStatus(data.item.id, $event)">
                            <template #first>
                                <b-form-select-option :value="null" disabled>Select Status</b-form-select-option>
                            </template>
                            <option
                                v-for="(option, id) in allStatuses"
                                :key="id"
                                :value="option.id"
                                :title="option.name || null"
                                :style="{color: option.color}"
                            >
                                {{option.name}}
                            </option>
                        </b-form-select>
                    </div>

                    <div class="item_status"
                         v-else
                         :style="{border: `1px solid black`, color: `black`}"
                    >
                        <b-form-select v-model="data.item.status_id" @change="changeStatus(data.item.id, $event)">
                            <template #first>
                                <b-form-select-option :value="null" disabled>Select Status</b-form-select-option>
                            </template>
                            <option
                                    v-for="(option, id) in allStatuses"
                                    :key="id"
                                    :value="option.id"
                                    :title="option.name || null"
                                    :style="{color: option.color}"
                            >
                                {{option.name}}
                            </option>
                        </b-form-select>
                    </div>

                </template>

                <template v-slot:cell(contact)="data">
                    <div class="item_contact">
                        <div class="item_contact-avatar"></div>
                        <div class="item_contact-info">
                            <div class="item_contact-info-name">{{data.item.name}}</div>
                            <div class="item_contact-info-tel">{{data.item.phone}}</div>
                            <div class="item_contact-info-email">{{data.item.email}}</div>
                        </div>
                    </div>
                </template>
<!--                <template v-slot:cell(funnel)="data">-->
<!--                    <div class="item_switch">-->
<!--                        <b-form-checkbox switch size="lg" :checked="1" :unchecked-value="0" :value="data.item.funnel_on_off" @change="funnelChange" />-->
<!--                    </div>-->
<!--                </template>-->
                <template v-slot:cell(notes)="data">
                    <div class="item_notes">
                        <div class="item_notes-note">notes(<span @click="noteRead(data.item)" id="read_note-btn">read</span>)</div>
                        <div class="item_notes-add" id="note-btn" @click="addNote(data.item)">+Add Notes</div>
                    </div>
                </template>
                <template v-slot:cell(address)="data">
                    <div class="item_address">
                        {{data.item.address}}
                    </div>
                </template>
<!--                <template v-slot:cell(selling)="data">-->
<!--                    <div class="item_selling">-->
<!--                        {{getSellingIn(data.item.selling_in)}}-->
<!--                    </div>-->
<!--                </template>-->
                <template v-slot:cell(received_date)="data">
                    <div class="item_date">
                        {{getDate(data.item.created_at)}}
                    </div>
                </template>
                <template v-slot:cell(info)="data">
                    <div class="item_info">
                        <div class="item_info-name">Source: <span class="item_info-value">&nbsp;{{data.item.source}}</span></div>
                        <div class="item_info-name">Type: <span class="item_info-value">&nbsp;{{getLeadType(data.item.lead_type)}}</span></div>
                    </div>
                </template>

                <template v-slot:cell(view)="data">
                    <router-link :to="'/leadsProfile/' + data.item.id">
                        <div class="item_view"></div>
                    </router-link>
                </template>

                <template v-slot:cell(delete)="data">
                    <div class="item_delete" id="lead_del-btn" @click="leadDeleteModal(data.item)">
                    </div>
                </template>

            </b-table>
        </div>

<!--Add Lead modal start-->
        <b-modal ref="lead-modal" centered hide-header hide-footer hide-header-close >
            <div class="lead_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="addLeadModal()"></b-icon>
                <div class="d-flex">
                    <div class="lead_title">
                        MANUALLY ADD A LEAD
                    </div>
                </div>

                <div class="input_content">
                    <div class="dual_inputs">
                        <div class="input_item">
                            <label>Name</label>
                            <b-form-input
                                    placeholder="e.g. John Smith"
                                    v-model="add_lead_data.name"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <span v-if="createLeadErrors['name']" class="text_danger mt-1">{{createLeadErrors['name'][0]}}</span>
                        </div>
                        <div class="input_item phone">
                            <label>Phone</label>
                            <b-form-input
                                    placeholder="000-000-0000"
                                    pattern="^\d{3}-\d{3}-\d{4}$"
                                    maxlength="10"
                                    onKeyPress="if(this.value.length==10) return false;"
                                    v-model="add_lead_data.phone"
                                    type="number"
                                    trim
                            ></b-form-input>
                            <span v-if="createLeadErrors['phone']" class="text_danger mt-1">{{createLeadErrors['phone'][0]}}</span>
                        </div>
                    </div>
                    <div class="single_input">
                        <div class="input_item">
                            <label>Email</label>
                            <b-form-input
                                    placeholder="example@gmail.com"
                                    v-model="add_lead_data.email"
                                    type="email"
                                    trim
                            ></b-form-input>
                            <span v-if="createLeadErrors['email']" class="text_danger mt-1">{{createLeadErrors['email'][0]}}</span>
                        </div>
                    </div>
                    <div class="single_input">
                        <div class="input_item">
                            <label>Street Address</label>
                            <b-form-input
                                    placeholder="enter location"
                                    v-model="add_lead_data.street_address"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <span v-if="createLeadErrors['street_address']" class="text_danger mt-1">{{createLeadErrors['street_address'][0]}}</span>
                        </div>
                    </div>

                    <div class="single_input">
                        <div class="input_item">
                            <label>Source</label>
                            <b-form-input
                                    placeholder="Source"
                                    v-model="add_lead_data.source"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <span v-if="createLeadErrors['source']" class="text_danger mt-1">{{createLeadErrors['source'][0]}}</span>
                        </div>
                    </div>

                    <div class="dual_inputs">
                        <div class="input_item">
                            <label>Lead type</label>
                            <div class="select_arrow">
                                <b-form-select v-model="add_lead_data.lead_type">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>Select</b-form-select-option>
                                    </template>
                                    <option
                                        v-for="(option, id) in leadType"
                                        :key="id"
                                        :value="option.id"
                                        :title="option.type || null"
                                    >
                                        {{option.type}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                                <span v-if="createLeadErrors['lead_type']" class="text_danger mt-1">{{createLeadErrors['lead_type'][0]}}</span>
                            </div>
                        </div>
                        <div class="input_item">
                            <label>Selling or buying in</label>
                            <div class="select_arrow">
                                <b-form-select v-model="add_lead_data.selling_in">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>Select</b-form-select-option>
                                    </template>
                                    <option
                                            v-for="(option, id) in sellingIn"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.title || null"
                                    >
                                        {{option.title}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                                <span v-if="createLeadErrors['selling_in']" class="text_danger mt-1">{{createLeadErrors['selling_in'][0]}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="single_input">
                        <div class="input_item">
                            <label>Choose Funnel</label>
                            <div class="select_arrow">
                                <Multiselect
                                        v-model="choosen_funnels"
                                        :options="funnels"
                                        label="funnel_text"
                                        track-by="id"
                                        :close-on-select="false"
                                        :hide-selected="true"
                                        multiple
                                ></Multiselect>
                                <div class="arrow_down"></div>
                                <span v-if="createLeadErrors['funnel_id']" class="text_danger mt-1">{{createLeadErrors['funnel_id'][0]}}</span>
                            </div>



<!--                            <div class="select_arrow">-->
<!--                                <b-form-select v-model="add_lead_data.funnel_id">-->
<!--                                    <template #first>-->
<!--                                        <b-form-select-option :value="null" disabled>Select</b-form-select-option>-->
<!--                                    </template>-->
<!--                                    <option-->
<!--                                            v-for="(option, id) in funnels"-->
<!--                                            :key="id"-->
<!--                                            :value="option.id"-->
<!--                                            :title="option.text || null"-->
<!--                                    >-->
<!--                                        {{option.text}}-->
<!--                                    </option>-->
<!--                                </b-form-select>-->
<!--                                <div class="arrow_down"></div>-->
<!--                                <span v-if="createLeadErrors['funnel_id']" class="text_danger mt-1">{{createLeadErrors['funnel_id'][0]}}</span>-->
<!--                            </div>-->


                        </div>
                    </div>
                </div>

                <div class="done_btn-container">
                    <b-button class="done_btn" @click="addNewLead()">Add Lead</b-button>
                </div>
            </div>
        </b-modal>
<!--Add Lead modal end-->

<!--Note modal start-->
        <b-modal ref="note-modal" centered hide-header hide-footer hide-header-close >
            <div class="note_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="noteModal()"></b-icon>
                <div class="d-flex">
                    <div class="note_title">
                        Add New Note
                    </div>
                </div>

                <div class="input_content">
                    <div class="input_item">
                        <label>Note</label>
                        <b-form-textarea
                                id="textarea"
                                v-model="sendNoteData.description"
                                placeholder="Enter something..."
                                rows="4"
                                max-rows="6"
                        ></b-form-textarea>
                    </div>
                </div>

                <div class="done_btn-container">
                    <b-button class="done_btn" @click="addNewNote()">Add Note</b-button>
                </div>
            </div>
        </b-modal>
<!--Note modal end-->

<!--Note modal start-->
        <b-modal ref="read_note-modal" centered hide-header hide-footer hide-header-close >
            <div class="note_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="readNoteModal()"></b-icon>
                <div class="d-flex">
                    <div class="note_title">
                        Note
                    </div>
                </div>

                <div class="notes_content">
                    <div class="note_item" v-for="item in allNotes">
                        <div class="note_dot"></div>
                        <div class="note_description">{{item.description}}</div>
                        <div class="note_buttons">
                            <div class="note_edit" @click="editNote(item)"></div>
                            <div class="note_delete" @click="deleteNote(item.id)"></div>
                        </div>
                        <div class="note_edit-content" :class="{'active_note': activeItem.id === item.id}">
                            <div class="note_textarea-container">
                                <b-form-textarea
                                    v-model="activeItem.description"
                                    placeholder="Enter something..."
                                    rows="2"
                                    max-rows="4"
                                ></b-form-textarea>
                            </div>
                            <div class="note_btn-content">
                                <b-button class="note_btn cancel_btn" @click="cancelNoteEdit()">Cancel</b-button>
                                <b-button class="note_btn save_btn" @click="noteEditSave()">Save</b-button>
                            </div>
                        </div>

                    </div>
                </div>

<!--                <div class="input_content">-->
<!--                    <div class="input_item">-->
<!--                        <label>Note</label>-->
<!--                        <b-form-textarea-->
<!--                                id="textarea"-->
<!--                                v-model="sendNoteData.description"-->
<!--                                placeholder="Enter something..."-->
<!--                                rows="4"-->
<!--                                max-rows="6"-->
<!--                        ></b-form-textarea>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="done_btn-container">-->
<!--                    <b-button class="done_btn" @click="addNewNote()">Add Note</b-button>-->
<!--                </div>-->
            </div>
        </b-modal>
<!--Note modal end-->

<!--Lead delete modal start-->
        <b-modal ref="lead_delete" centered hide-header hide-footer hide-header-close>
            <div class="lead-delete_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="leadDelModal()"></b-icon>
                <div class="d-flex">
                    <div class="lead-delete_title">
                        You are about to delete this lead
                    </div>
                </div>
                <div class="lead-delete_message">
                    Do you want to proceed?

                </div>

                <div class="delete_btn-container">
                    <b-button class="delete_btn" @click="removeLead()">Delete</b-button>
                </div>
            </div>
        </b-modal>
<!--Lead delete modal end-->
    </div>
</template>

<script>

    import {mapActions} from "vuex";

    export default {
        name: "DashboardLeads",
        data() {
            return {
                keyword: '',
                selectedLeadType: '',
                status_selected: null,
                sendNoteData: {
                    lead_id: null,
                    description: ''
                },
                noteText: '',
                readNoteLeadId: null,
                allNotes: null,
                all_leads: [],
                fields: [
                    {
                        key: 'status',
                        label: 'STATUS',
                    },
                    {
                        key: 'contact',
                        label: 'NAME & CONTACT',
                    },
                    {
                        key: 'notes',
                        label: 'NOTES',
                    },
                    {
                        key: 'address',
                        label: 'ADDRESS',
                    },
                    // {
                    //     key: 'selling',
                    //     label: 'SELLING IN',
                    // },
                    {
                        key: 'received_date',
                        label: 'DATE RECEIVED',
                    },
                    {
                        key: 'info',
                        label: 'INFO',
                    },
                    {
                        key: 'view',
                        label: 'VIEW / EDIT',
                    },
                    {
                        key: 'delete',
                        label: 'DELETE',
                    },
                ],
                borderPosition: true,
                add_lead_data: {
                    name: null,
                    phone: null,
                    email: null,
                    street_address: null,
                    lead_type: null,
                    source: null,
                    selling_in: null,
                    funnel_id: []
                },
                choosen_funnels: [],
                leadType: [
                    {
                        type: 'Seller',
                        id: 1
                    },
                    {
                        type: 'Buyer',
                        id: 0
                    }
                ],
                sellingIn: [
                    {
                        id: 0,
                        title: '1-3 months'
                    },
                    {
                        id: 1,
                        title: '3-6 months'
                    },
                    {
                        id: 2,
                        title: '6-12 months'
                    },
                    {
                        id: 3,
                        title: 'Not Sure'
                    }

                ],
                funnels: [],
                createLeadErrors: {},
                allStatuses: [],
                activeItem: {
                    id: null
                },
                deleteLeadId: null
            }
        },
        computed: {
            filteredLeads() {
                let filteredItems = this.all_leads.filter((f_lead) => {
                    return f_lead.name.toLowerCase().includes(this.keyword.toLowerCase());
                })
                return  filteredItems.filter((lead) => {
                    return lead.lead_type.toString().includes(this.selectedLeadType)
                })
            },

        },
        created() {
            alert.$on("clickOn",border =>{
                this.borderPosition = border;
            })
            this.getAllLeads()
            .then( res => {
                if(res.status === 200) {
                    this.all_leads = res.data.success
                    // console.log(this.all_leads)
                }
            })
            this.getAllStatuses()
                .then(res => {
                    if(res.status === 200) {
                        this.allStatuses = res.data.success
                    }
                })
            this.getAllFunnels()
            .then(res => {
                if(res.status === 200) {
                    this.funnels = res.data.success
                }
            })
        },
        methods: {
            ...mapActions([
                'getAllLeads',
                'createNewLead',
                'getAllStatuses',
                'getAllFunnels',
                'noteAdd',
                'readNotes',
                'removeNote',
                'noteEdit',
                'editLeadStatus',
                'leadRemove'
            ]),
            getDate(item) {
                return new Date(item ).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
            },
            changeStatus(id, event) {
                let statusData = {
                    lead_id: id,
                    status_id: event
                }
                this.editLeadStatus(statusData)
                    .then(res => {
                        if(res.status === 200) {
                            this.getAllLeads()
                                .then( res => {
                                    if(res.status === 200) {
                                        this.all_leads = res.data.success
                                    }
                                })
                        }
                    })
            },
            // getStatusData(id) {
            //     return this.allStatuses.forEach(status => {
            //         if (status.id === id) {
            //             return status.color
            //         }
            //     })
            // },
            newLead() {
                this.add_lead_data.name = null
                this.add_lead_data.phone = null
                this.add_lead_data.email = null
                this.add_lead_data.street_address = null
                this.add_lead_data.lead_type = null
                this.add_lead_data.source = null
                this.add_lead_data.selling_in = null
                this.add_lead_data.funnel_id = []
                this.createLeadErrors = {}
                this.addLeadModal()
            },
            addLeadModal() {
                this.$refs['lead-modal'].toggle('#lead-btn')
            },
            addNewLead() {
                this.choosen_funnels.forEach(funnel => {
                    this.add_lead_data.funnel_id.push(funnel.id)
                })
                this.createNewLead(this.add_lead_data)
                .then(res => {
                    if(res.status === 200) {
                        this.addLeadModal()
                        this.getAllLeads()
                            .then( res => {
                                if(res.status === 200) {
                                    this.all_leads = res.data.success
                                }
                            })
                    } else {
                        this.createLeadErrors = res.response.data.errors
                    }
                })
            },
            // funnelChange(args) {
            //     console.log(args)
            // },
            getSellingIn(args) {
                switch (args) {
                    case 0: return '1-3 months';
                    case 1: return '3-6 months';
                    case 2: return '6-12 months';
                    case 3: return 'Not Sure';
                }
            },
            getLeadType(args) {
              if(args === '0') {
                  return 'Buyer'
              }  else {
                  return 'Seller'
              }
            },
            addNote(item) {
                this.sendNoteData.lead_id = item.id
                this.sendNoteData.description = ''
                this.noteModal()
            },
            noteModal() {
                this.$refs['note-modal'].toggle('#note-btn')
            },
            addNewNote() {
                this.noteAdd(this.sendNoteData)
                .then(res => {
                    if(res.status === 200) {
                        this.noteModal()
                        this.getAllLeads()
                            .then( res => {
                                if(res.status === 200) {
                                    this.all_leads = res.data.success
                                }
                            })
                    }
                })
            },
            noteRead(item) {
                this.readNoteLeadId = item.id
                this.readNotes(item.id)
                .then(res => {
                    if(res.status === 200) {
                         this.allNotes = res.data.success
                        if(this.allNotes.length) {
                            this.readNoteModal()
                        }
                    }
                })

            },
            readNoteModal() {
                this.$refs['read_note-modal'].toggle('#read_note-btn')
            },
            deleteNote(id) {
                this.removeNote(id)
                this.readNotes(this.readNoteLeadId)
                    .then(res => {
                        if(res.status === 200) {
                            this.allNotes = res.data.success
                        }
                    })
            },
            editNote(item) {
                this.activeItem.id = item.id
                this.activeItem.description = item.description
            },
            cancelNoteEdit() {
                this.activeItem.id = null
                this.activeItem.description = ''
            },
            noteEditSave() {
                this.noteEdit(this.activeItem)
                .then(res => {
                    if(res.status === 200) {
                        this.activeItem.id = null
                        this.activeItem.description = ''
                        this.readNotes(this.readNoteLeadId)
                            .then(res => {
                                if(res.status === 200) {
                                    this.allNotes = res.data.success
                                }
                            })
                    }
                })
            },
            leadDeleteModal(item) {
                this.deleteLeadId = item.id
                this.leadDelModal()
            },
            leadDelModal() {
                this.$refs['lead_delete'].toggle('#lead_del-btn')
            },
            removeLead() {
                this.leadRemove(this.deleteLeadId)
                .then(res => {
                    if(res.status === 200) {
                        this.leadDelModal()
                        this.getAllLeads()
                            .then( res => {
                                if(res.status === 200) {
                                    this.all_leads = res.data.success
                                }
                            })
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">

.page {
    &_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1500px;
        width: 100%;
        margin: 0 auto;
        transition: .4s;
        &.straight_length {
            max-width: 1700px;
            transition: .4s;
        }

    }
    &_head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px;
        &-item {
            display: flex;
            align-items: center;
            select {
                width: 260px;
                background: #fff url("~@/assets/images/arrow_down.svg") right 0.75rem center/8px 10px no-repeat;
            }
            .search_loop {
                position: relative;
                margin: 0 15px;
                .s_loop {
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    top: 10px;
                    right: 10px;
                    background-position: center;
                    pointer-events: none;
                    background: url("~@/assets/images/search_loop.svg") no-repeat;
                }

            }
        }
        &-title {
            color: $main_blue;
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 0;
            text-transform: uppercase;
        }
        &-new-btn {
            width: 160px;
            height: 44px;
            border-radius: 5px;
            background: $light_blue;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            border: none;
            &:focus {
                box-shadow: none;
            }
            &:active {
                background: $main_blue !important;
            }
        }
        &-total-btn {
            width: 126px;
            height: 44px;
            border: 1px solid #00457B;
            border-radius: 22px;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            color: $main_blue;
            margin: 0 15px;
        }
    }
    &_content {
        width: 100%;
        padding: 10px 30px 0;
        ::v-deep table {
            border: 1px solid #dee2e6;
            td {
                vertical-align: middle;
            }
            td:nth-child(7) {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 98px;
            }
        }
        ::v-deep .table thead th {
            color: $main_blue;
            text-transform: uppercase;
        }
        ::v-deep .custom-select {
            border: none;
        }
        ::v-deep .item_status {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            border-radius: 5px;
            .custom-select {
                border-radius: 5px;
                color: inherit;
                background: #fff url("~@/assets/images/arrow_down.svg") right 0.75rem center/8px 10px no-repeat;
                &:focus {
                    border: none;
                    box-shadow: none;
                }
            }
        }
        .item_contact {
            display: flex;
            align-items: center;
            &-avatar {
                width: 62px;
                height: 62px;
                background: url("~@/assets/images/user_avatar.svg");
            }
            &-info {
                padding: 4px 2px 4px  30px;
                &-name {
                    color: $light_blue;
                    font-size: 16px;

                }
                &-tel {
                    color: $black;
                    font-size: 14px;

                }
                &-email {
                    color: $black;
                    font-size: 14px;

                }
            }
        }
        .item_switch {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .item_notes {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &-note {
                font-size: 14px;
                color: $black;
                span {
                    color: $light_blue;
                    cursor: pointer;
                }
            }
            &-add {
                font-size: 14px;
                color: $light_blue;
                cursor: pointer;
            }
        }
        .item_address {
            max-width: 140px;
            width: 100%;
            font-size: 14px;
            word-break: break-word;
        }
        .item_selling,
        .item_date {
            font-size: 14px;
        }
        .item_info {
            font-size: 14px;
            span {
                color: $light_blue;
            }
        }
        .item_view {
            width: 30px;
            height: 20px;
            background: url("~@/assets/images/view_icon.svg") center no-repeat;
            background-size: contain;
            cursor: pointer;
        }
        .item_delete {
            width: 24px;
            height: 30px;
            background: url("~@/assets/images/trash_icon.svg") center no-repeat;
            background-size: contain;
            padding: 0 30px;
            cursor: pointer;
        }
        /*.item_center {*/
        /*    display: flex;*/
        /*    justify-content: center;*/
        /*}*/
    }
}
.lead-delete {
    &_modal_content {
        position: relative;
        padding: 0 20px 10px;
        width: 560px;

        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: #B4BABC;
        }

        & .delete_btn {
            background: $light_blue;
            box-shadow: 0 3px 6px #00000029;
            border-radius: 5px;
            width: 50%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-weight: 400;
            font-size: 18px;
            border: none;

            &-container {
                display: flex;
                justify-content: center;
                padding: 20px 0 5px;
            }
        }
    }

    &_message {
        padding: 20px 30px 40px;
        color: $grey;
        font-size: 22px;
    }

    &_title {
        width: 100%;
        font-size: 25px;
        font-weight: 500;
        color: $main_blue;
        padding: 20px 0 10px;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
    }
}
.lead {
    &_modal_content {
        position: relative;
        padding: 0 20px;
        width: 560px;
        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: #B4BABC;
        }
        & .input_content {
            display: flex;
            flex-direction: column;
            & .dual_inputs {
                display: flex;
                justify-content: space-between;
                width: 100%;
                &-switch {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .input_item-switch {
                        padding: 5px 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        & label {
                            color: $black;
                            font-weight: 500;
                            font-size: 16px;
                        }
                    }
                    .input_item {
                        padding: 5px 0;
                        width: 374px;
                        & .select_arrow {
                            position: relative;
                            & .arrow_down {
                                position: absolute;
                                top: 14px;
                                right: 15px;
                                width: 14px;
                                height: 10px;
                                pointer-events: none;
                                background: url("~@/assets/images/arrow_down.svg") right  no-repeat;
                            }
                        }
                        & label {
                            color: $black;
                            font-weight: 500;
                            font-size: 16px;
                        }
                        & select {
                            color: black;
                            border: 1px solid #798387;
                            background: #fff url("~@/assets/images/arrow_down.svg") right 0.75rem center/10px 10px no-repeat;
                            border-radius: 5px;
                            padding-right: 5px;
                        }
                    }
                }
                & .input_item {
                    padding: 5px 0;
                    width: 250px;
                    &.phone {
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        input[type=number] {
                            -moz-appearance: textfield;
                        }
                    }

                    & .select_arrow {
                        position: relative;
                        & .arrow_down {
                            position: absolute;
                            top: 14px;
                            right: 15px;
                            width: 14px;
                            height: 10px;
                            pointer-events: none;
                            background: url("~@/assets/images/arrow_down.svg") right  no-repeat;
                        }
                    }
                    & label {
                        color: $black;
                        font-weight: 500;
                        font-size: 16px;
                    }
                    & input {
                        border: 1px solid #798387;
                        border-radius: 5px;
                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }
                    }
                    & select {
                        color: black;
                        border: 1px solid #798387;
                        background: #fff url("~@/assets/images/arrow_down.svg") right 0.75rem center/10px 10px no-repeat;
                        border-radius: 5px;
                        padding-right: 5px;
                    }
                }
            }
            & .single_input {
                & .input_item {
                    padding: 5px 0;
                    width: 100%;
                    & .select_arrow {
                        position: relative;
                        & .arrow_down {
                            position: absolute;
                            top: 14px;
                            right: 15px;
                            width: 14px;
                            height: 10px;
                            pointer-events: none;
                            background: url("~@/assets/images/arrow_down.svg") right  no-repeat;
                        }
                    }
                    & label {
                        color: $black;
                        font-weight: 500;
                        font-size: 16px;
                    }
                    & input {
                        border: 1px solid #798387;
                        border-radius: 5px;
                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }
                    }
                    & select {
                        color: black;
                        border: 1px solid #798387;
                        background: #fff url("~@/assets/images/arrow_down.svg") right 0.75rem center/10px 10px no-repeat;
                        border-radius: 5px;
                        padding-right: 5px;
                    }
                }
            }
        }
        & .done_btn-container {
            display: flex;
            justify-content: center;
            padding: 20px 0 10px;
            & .done_btn {
                background: $light_blue;
                box-shadow: 0 3px 6px #00000029;
                border-radius: 5px;
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                font-weight: 400;
                font-size: 18px;
                border: none;
            }
        }
    }
    &_title {
        width: 100%;
        font-size: 25px;
        font-weight: 500;
        color: $main_blue;
        padding: 20px 0 10px;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
    }
}
.note {
    &_modal_content {
        position: relative;
        padding: 0 20px;
        width: 560px;
        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: #B4BABC;
        }
        & .input_content {
            display: flex;
            flex-direction: column;
            & .input_item {

            }
        }
        & .done_btn-container {
            display: flex;
            justify-content: center;
            padding: 20px 0 10px;
            & .done_btn {
                background: $light_blue;
                box-shadow: 0 3px 6px #00000029;
                border-radius: 5px;
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                font-weight: 400;
                font-size: 18px;
                border: none;
            }
        }
        & .notes_content {
            width: 100%;
            padding: 20px 0 30px;
            & .note {
                &_item {
                    display: flex;
                    align-items: center;
                    padding: 15px 0;
                    border-bottom: 1px solid $blue_grey;
                    position: relative;
                }
                &_dot {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background: $light_blue;
                    box-shadow: inset 0 0 0 2px $white;
                    border: 2px solid $light_blue;
                    background-size: cover;
                }
                &_description {
                    margin: 0 10px;
                    font-size: 18px;
                    max-width: 420px;
                    width: 100%;
                }
                &_buttons {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 60px;
                }
                &_textarea-container {
                    padding: 20px 10px 5px;
                    & textarea {
                        overflow: auto;
                        border: 1px solid #dee2e6;
                        resize: none;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                        &:focus {
                            outline: none !important;
                            border: none !important;
                            box-shadow: none !important;
                        }
                    }
                }
                &_edit {
                    width: 18px;
                    height: 18px;
                    background: url("~@/assets/images/edit_icon.svg") no-repeat center;
                    background-size: cover;
                    cursor: pointer;
                    &-content {
                        position: absolute;
                        bottom: -50%;
                        left: 0;
                        width: 520px;
                        background: $light_grey;
                        display: none;
                        z-index: 2;
                        &.active_note {
                            display: block;
                        }
                        & .note_btn {
                            width: 90px;
                            height: 34px;
                            margin: 0 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 4px 20px;
                            border: none;
                            &-content {
                                padding: 10px 0;
                                display: flex;
                                justify-content: flex-end;
                            }
                            &.save_btn {
                                background: $light_blue;

                            }
                            &.cancel_btn {
                                background: #ccd0d0;
                                color: $darkBlue;

                            }
                        }
                    }
                }
                &_delete {
                    width: 18px;
                    height: 20px;
                    background: url("~@/assets/images/trash_icon.svg") no-repeat center;
                    background-size: cover;
                    cursor: pointer;
                }
            }
        }
    }
    &_title {
        width: 100%;
        font-size: 25px;
        font-weight: 500;
        color: $main_blue;
        padding: 20px 0 10px;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
    }

}
::v-deep .modal-content {
    border: none !important;
    width: 600px;
    ::v-deep .modal-body {
        width: 580px !important;
    }
}
::v-deep .select_arrow {
    .multiselect__tags {
        .multiselect__placeholder {
            padding: 7px 0 6px 10px;
            margin-bottom: 0;
        }

        .multiselect__input {
            padding-left: 10px !important;
        }
    }
}

::v-deep .multiselect {
    width: 100%;
    height: 38px;
    min-height: 38px;
    background: transparent 0 0 no-repeat padding-box;
    cursor: pointer;
    border: 1px solid #798387;
    border-radius: 5px;
}

::v-deep .multiselect__tags {
    background: transparent;
    padding-left: 0;
    min-height: 38px;
    padding-top: 0;
}

::v-deep .multiselect__tag {
    background: transparent;
    color: $black;
    margin-top: 6px;
}

::v-deep .multiselect__tag-icon:after {
    color: red;
}

::v-deep .multiselect__tag-icon:hover {
    background: transparent;
}

::v-deep .multiselect__option--highlight,
::v-deep .multiselect__option--highlight:after {
    background: $blue_grey;
    color: $black;
}

::v-deep .multiselect__select:before {
    border-width: 0;
    border-style: none;
}

::v-deep .multiselect__input {
    margin: 0;
    padding: 0;
}
</style>